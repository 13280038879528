.circles-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.dot {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: transform 400ms;
  transform: rotate(0);
}

.dot:after {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  border-radius: 100%;
  height: 34%;
  width: 33.75%;
  background: transparent;
  box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.24);

}
.ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.ring:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.ring:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 33%;
  width: 33%;
  background: #fff;
  border-radius: 50%;
}
@media (prefers-color-scheme: dark) {
  .ring:after {
    background-color: rgb(43, 48, 53) !important;
  }
}

.textc {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 5;
  color: #fff;
  font-weight: 900;
  font-size: 15px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0);
}
.progress-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
}

.progress-ring__circle {
  transition: stroke-dashoffset 350ms;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.stand.ring {
  /* background: rgba(67, 69, 70, 0.32); */
  background: rgba(21, 194, 224, .32);

}
.stand.ring:after {
  /* box-shadow: inset 1px 0 3px rgba(67, 69, 70, 0.48); */
  box-shadow: inset 1px 0 3px rgba(21, 194, 224, .48);
}

@media only screen and (max-width: 767.98px) {
  .cal {
    width: 50px;
    height: 50px;
  }
}
