
.nav-link {
  position: relative;
  font-weight: 600 !important;
}

.nav-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  background-color: #3F8AB6;
}


.navbar-nav .nav-link.active::before {
  transform: scaleX(0.99) !important;
}
.nav-link:hover::before {
  transform: scaleX(0.99);
}

 /* <Navbar id="navbar-nav">
<Navbar.Brand as={Link} to="/" >
  <div className="d-flex flex-column align-items-center px-3" style={{ fontSize: "10px" }}>
    <img src="apple-touch-icon.png" height="30px" alt="Logo" />
  </div>
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsOpen(!isOpen)} />
<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end px-3 " in={isOpen} onSelect={handleSelect}>
  <Nav activeKey={activeKey}>
    <Nav.Link as={Link} to="/Articles" eventKey="/Articles" className="header" onClick={() => setIsOpen(false)}>
      Articles
    </Nav.Link>
    <Nav.Link as={Link} to="/Services" eventKey="/Services" className="header" onClick={() => setIsOpen(false)}>
      Music
    </Nav.Link>
    <Nav.Link as={Link} to="/Movies" eventKey="/Movies" className="header" onClick={() => setIsOpen(false)}>
      Movies
    </Nav.Link>
    <Nav.Link as={Link} to="/Exercise" eventKey="/Exercise" className="header" onClick={() => setIsOpen(false)}>
      Exercise
    </Nav.Link>
  </Nav>
</Navbar.Collapse>
</Navbar>  */