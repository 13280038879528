body {
  margin: 0;
  font-family: Verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  -webkit-text-size-adjust: 100%;
}
.root-main {
  display: flow-root;
  min-height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #cac3c0;
}
select {
  font-weight: bold;
  box-shadow: none;
  background: none;
  border: none;
  padding-top: 5px;
}
.root-main {
  background-color: #F8F9FA;
}
.rc-drops {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 
  0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 5px 2px;
  padding: 5px 5px;
  border-radius: 0.28571429rem;
  background: #F2F2F7;
  position: relative;
  animation-name: slideInFromTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.ui.cards > .card > .image > object,
.ui.card > .image > object {
  display: block;
  width: 100%;
  height: auto;
  border-radius: inherit;
}
table {
  width: 100% !important; 
  border-collapse: collapse;
}
.bg-blur {
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 
  0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 5px 2px;
  padding: 5px 5px;
  border-radius: 0.28571429rem;
  background: #fcfcfc;
  position: relative;
  animation-name: slideInFromTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.obj-img {
  margin-left: -30px;
  height: 25vh !important;
}
.event {
  background-color: #ffcc00;
  padding: 2px;
  margin-top: 2px;
  border-radius: 4px;
  font-size: 0.8em;
}
.content {
  padding: 7px 10px;
}
@keyframes slideInFromTop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fill-svg {
  fill: black;
}
.bodysvg {
  width: 85px;
  height: 85px;
  padding: 5px 0;
}
.bodysvg1 {
  width: 185px !important;
  height: 185px !important;
  margin-right: 30px;
}

@media (prefers-color-scheme: dark) {
  .modal-content,
  .root-main,
  .offcanvas,
  .tab-content,
  .h-text,
  .segment,
  select,
  .active.item,
  td,
  th,
  .card,
  input,
  textarea,
  .accordion .title,
  .ui.basic.button,
  .ui.horizontal.divider,
  .ui.steps .disabled.step,
  .ui.steps .step,
  caption,
  input::placeholder {
    background-color: #373f4e !important;
    color: #e0e4eb !important;
  }
  .rc-drops {
    background-color: #4e576a !important;
    color: #fff !important;
  }

  .accordion .title,
  .ui.steps .disabled.step,
  .ui.steps .step {
    border: 1px solid #d4d4d5 !important;
    box-shadow: 0 1px 2px 0 rgba(255, 255, 255, 0.15), 0 0 0 1px rgba(255, 255, 255, 0.15) !important;
  }

  .card,
  .meta,
  .header,
  .menu,
  .item .content,
  .description,
  ol.ui.suffixed.list li::before,
  .ui.vertical.divider,
  .date,
  .content .extra,
  .divider,
  .input-color,
  .icon {
    color: #fff !important;
  }
  .fill-svg {
    fill: white;
  }
  .location::before {
    background-color: rgba(255, 255, 255, 0.226) !important;
  }
}
.ui.list > .item > img.image + .content {
  display: inline-block;
  width: 90% !important;
}
.ui.Small.image.post {
  width: 100% !important;
  max-height: 300px !important;
}
.ui.pagination.menu {
  font-size: 12px;
}
.tab-content,
.tab-pane {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 767.98px) {
  .ui.cards {
    margin: 0 !important;
  }
  .ui.list > .item > img.image + .content {
    display: inline-block;
    width: 70% !important;
  }
  .column,
  div.ui.container {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.ui.tree.accordion .accordion > .content {
  margin-left: 0 !important;
}
.ui.styled.accordion .accordion {
  box-shadow: none !important;
}
.ui.styled.accordion > .content {
  padding: 0 !important;
}
.pe-on {
  cursor: pointer;
}
audio {
  width: 100%;
}

.ui.label {
  font-size: 10px !important;
  margin-top: 5px !important;
}

.ui.avatar.image.movie {
  height: auto !important;
  width: 85px !important;
  border-radius: 0 !important;
}
.picker {
  display: grid;
  grid-auto-flow: column;
  gap: 2ch;
  height: 60px;
}

.picker::before,
.picker::after {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(50% - 3.5ex);
  pointer-events: none;
}

.picker::after {
  top: auto;
  bottom: 48px;
}

.picker > * {
  display: grid;
  grid-auto-rows: 18vmin;
  align-items: center;
  max-block-size: calc(9vmin * 5);
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  padding-inline-end: 1ch;
  font-weight: lighter;
  text-align: start;
  /* margin-bottom: 2ch; */
}

.picker > [title]:first-child::before {
  text-align: end;
}

.picker > * > * {
  scroll-snap-align: center;
}
.picker > * > *:last-child {
  padding-bottom: 3ch;
  margin-bottom: 3ch;
}
.picker > [title]::before {
  content: attr(title);
  position: sticky;
  top: 0;
  align-self: start;
  justify-self: start;
  z-index: 1;
  font-weight: bold;
  text-align: center;
}
.Input-form {
  display: grid;
  grid-auto-flow: column;
  gap: 2ch;
  height: 55px;
}

.Input-form::before,
.Input-form::after {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(50% - 3.5ex);
  pointer-events: none;
}

.Input-form::after {
  top: auto;
  bottom: 0;
}
.Input-form > * {
  display: grid;
  padding-inline-end: 1ch;
  font-weight: lighter;
  text-align: start;
}

.Input-form > [title]::before {
  content: attr(title);
  position: sticky;
  top: 0;
  align-self: start;
  justify-self: start;
  z-index: 1;
  font-weight: bold;
  text-align: center;
}

.Input-form > [title]:first-child::before {
  text-align: end;
}

.Input-form > * > * {
  scroll-snap-align: center;
}
