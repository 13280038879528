.hdr {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.sub-hdr {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(21, 194, 224, 0.32) !important;
  color: black !important;
}
/* .react-calendar__tile--now {
    background: rgb(184, 198, 201) !important;
} */
.calendar-event > * {
  max-width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  font-size: 0.733em;
}
.react-calendar {
  /* width: 100% !important; */
  height: 100% !important;
}
.box {
  position: relative;
  width: 250px;
  height: 250px;
  margin: auto;
}
.cal-box {
  width: 30px;
  height: 30px;
  margin: auto;
  text-align: center;
}
.cal {
  width: 75px;
  height: 75px;
  margin: auto;
  text-align: center;
}
.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: rgba(21, 194, 224, 0.95);
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #000;
}
.scrl-on {
  max-height: 450px;
  overflow-y: auto;
}
.scrl-on1 {
  max-height: 250px;
  overflow-y: auto;
}
.max-view {
  max-height: 100vh;
  overflow-y: auto;
}
.cal1 {
  width: 85px;
  height: 85px;
  margin: auto;
  text-align: center;
}
input > * {
  font-size: 16px !important;
}
.lft-btn {
  border-radius: 0 0.375rem 0.375rem 0 !important;
}
@media (prefers-color-scheme: dark) {
  .react-calendar__tile--now {
    background: none !important;
    border: 2px solid #ffff76 !important;
  }
  .react-calendar {
    background: transparent;
    color: #e0e4eb !important;
  }
  .react-calendar * {
    color: #e0e4eb !important;
  }
}
@media only screen and (max-width: 767.98px) {
  .prjt {
    width: 100% !important;
  }
  .cal1 {
    width: 55px;
    height: 55px;
    text-align: center;
  }
}
