.profile .ui.tiny.circular.image{
  position: absolute;
  bottom: 160px;
}
.profile .ui.grey.label{
  padding: 5px 10px !important;

}
.ui.tiny.circular.image{
  border: 2px solid black;

}
.location::before{
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  bottom: 0;
  width: 5px;
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.226);
}
.rc-menu{
  margin-left: 0 !important;
}
.options{
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.marquee {
  position: relative;
  overflow: hidden;
}

.marquee-slider {
  display: flex;
  transition: transform 0.5s ease;
  will-change: transform;
  overflow: hidden;
}
.marquee-wrapper {
  position: relative;
  height: 100%;
  padding: 0 3rem;
}

.marquee-wrapper img {
  position: absolute;
  width: 1156px;
  height: auto;
  left: 0;
  top: 0;
  object-position: top right;
  object-fit: cover;
}
.marquee-wrapper img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3)) !important;
}

.marquee-text {
  position: relative;
  width: 50%;
  height: 50vh;
  color: #fff;
  text-transform: uppercase;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.marquee-text h1, .marquee-text button {
  border-radius: 5px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  padding: 10px 5%;
  font-size: 40px;
  width: 100%;
}
.marquee-text p {
  border-radius: 5px;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  padding: 10px 5%;
  font-size: 20px;
  width: 100%;
}
.marquee-prev,
.marquee-next {
  position: absolute;
  top: 50%;
  font-size: 30px;
  font-weight: 900;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marquee-next {
  left: 10px;
}
.marquee-prev {
  right: 10px;
  z-index: 1;
}
.marquee-dots {
  list-style-type: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.marquee-dots li {
  display: inline-block;
  margin: 0 5px;
}
.marquee-dots li button {
  font-size: 0;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  border: 0;
  opacity: 0.75;
}
.dots-active {
  transition: all 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  box-shadow: #ededed 0 0 0 1px inset, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
  background-color: black;
  opacity: 1 !important;
}
/*  lg: width: 1127px
    md: width: 933px;
    sm: width: 723px;
    mb: width: 100% */
@media screen and (max-width: 630px) {
  .marquee-text h1 {
    font-size: 18px;
  }
  .marquee-text p {
    font-size: 11px;
  }
  .marquee-text {
    width: 100%;
  }
}
.cpimg {
  background-image: url(/public/assets/marquee/onlineimage.jpeg);
  background-size: cover;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
