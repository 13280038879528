.share-buttons-container {
  display: flex;
  justify-content: center;
}

.share-buttons {
  display: flex;
  gap: 10px;
}

.dropdown .btn {
  background-color: transparent !important;
  border: none !important;
  border: 1px solid black !important;
  margin: 0;
  padding: 0 5px 0 5px;
  box-shadow: none !important;
}

.eyes {
  padding: 0 5px 0 5px;
}

.like {
  cursor: pointer;
}

.markdown-content {
  padding: 0 4%;
}
.markdown-content h1 {
  font-size: 24px;
}
.markdown-content h2 {
  font-size: 20px;
}
.markdown-content p::first-letter {
  margin-left: 25px;
}
.markdown-content p:nth-child(7) {
  margin-top: 25px;
}

.markdown-content p:nth-child(6)::first-letter {
  font-size: 3em;
  float: left;
  line-height: 1em;
  padding: 0 10px;
}

.markdown-content .btn-primary {
  width: 100%;
  margin-bottom: 5px;
}
.markdown-content img {
  max-width: 50% !important;
  margin: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

::-webkit-scrollbar {
  height: 8px;
  /* Makes the scrollbar thinner */
}

::-webkit-scrollbar-thumb {
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Rounds the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Scrollbar track color */
}

.like-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu,
.dropdown-item {
  padding: 5px 5px 5px 5px !important;
}

.like-button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  padding: 0 5px 0 5px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  outline: none;
}

.like-button.liked {
  color: red;
}

.like-button.animate {
  animation: pop 0.7s ease forwards;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

pre {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  white-space: pre;
}

code {
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 14px;
  color: #d14;
}
.articles ul {
  padding: 0;
  margin: 0;
}

.articles ul li {
  margin: 10px 5px;
  width: 250px;
  height: auto;
  min-height: 240px;
}
.articles ul li img {
  width: auto;
  height: 160px;
}

.articles ul li p {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  color: #333;
}
.articles ul li h1 {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  color: #333;
  font-size: medium;
}
.articles-view ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.slides  > * {
  display: grid;
  grid-auto-rows: 9vmin;
  grid-auto-flow: column;
  align-items: center;
  max-block-size: 250px;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
.slides > * > * {
  scroll-snap-align: center;
}
.ui.avatar.image.blog{
  width: 50px !important;
  height: 50px !important;
  border-radius: 0 !important;
}
.ui.list.article-list > .item {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding-left: 5px;
}

.ui.list.article-list > .item > img.image + .content {
  display: inline-block;
  width: 50% !important;
}
.ui.Small.image.post{
  width: 100% !important;
  height: 250px !important;
}
.slides1 {
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
}
.slide {
  --margin: max(calc(((var(--cols) - 1) / var(--cols)) * var(--gap)), 0rem);
  --width: calc((var(--container) / var(--cols)) - var(--margin));
  background: lightblue;
  padding: 1rem;
  min-width: var(--width);
  scroll-snap-align: start;
  height: 10rem;
  &.ghost {
    padding: 0;
    margin: 0;
    background: none;
    min-width: var(--layout-margin);
  }
}
.slide + .slide:not(:nth-last-child(2)) {
  margin-right: var(--gap);
}

.articles-view ul li {
  margin: 10px;
  min-width: 200px;
  min-height: 100px;
}
.articles-view ul li img {
  width: auto;
  height: 60px;
}
.articles-view ul li p {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  color: #333;
}
.articles-view ul li h1 {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  color: #333;
  font-size: 10px;
}
.blog-tile {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}
.blog-tile:hover {
  background-color: #f7fafa;
}
@media screen and (max-width: 630px) {
  .ui.Small.image.post{
    width: 100% !important;
    height: auto !important;
  }
  .articles ul li {
    width: 90%;
  }
  .ui.avatar.image.blog{
    width: 30px !important;
    height: 30px !important;
    border-radius: 0 !important;
  }
  .slides  > * {
    grid-auto-rows: 11vmin;
  }
}
